<template>
  <Row class="page3">
    <Col style="width:48%;padding:0 1%;">
      <div class="description">
        <span class='title'><span class="title-text">{{picsTitle}}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span>{{describeList[chooseIndex]}}</span>
        </div>
      </div>
      <div class="left">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col style="width:51.5%;height: 91%;display: flex;flex-direction: column">
      <div class="top-list">
        <span class='title'><span class="title-text">项目背景介绍</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <span class="describe">在宁乡金洲村，佛光强县志质者为一名受灾“五保户”捡拾时，刚到门口等接到一段浓浓的刺皇异味，但老人家还住在里面。去愿者们奋战10多人，把房间清归得干净舒活。我们在同行的草位闻事的下小时讲课下，激动了地写:“现场，所有人，回想在日本听CODE日本志愿者看懂在救灾中的日常行动，当时我觉得日本救灾工作宣传，跟日本的大隔阂，现在我要说这话了，中国的灾祸的中国的志愿者部署甚微!</span>
      </div>
      <div class="center-list">
        <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <task-status ref="taskStatus"></task-status>
      </div>
      <div class="bottom-list" style="display:flex;">
        <div class="bottom-left">
          <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <workPics ref="workPic" :isBig="true"></workPics>
        </div>
        <div class="bottom-right" style="margin-left: 2%">
          <span class='title'><span class="title-text">荣誉图片</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="true"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div v-for="(item,index) in workName" :key="index" @click="chooseNav(item,index)" :class="index==chooseIndex?'on_workname':'workname'">{{item}}</div>
    </div>
  </Row>
</template>

<script>
const chinaMap  = () => import('./components/chinaMap');
// const dataTotal =()=> import('./components/page4/data-total')
const taskStatus =()=> import('./components/page4/taskStatus')
const workPics = () => import('./components/page2/workPictures');
const honorPics = ()=> import('./components/page2/HonoraryPics.vue');

export default {
  name: 'page4',
  components: {
    chinaMap,
    // dataTotal,
    taskStatus,
    workPics, //项目图片
    honorPics, //荣誉图片
  },
  data() {
    return {
      //tabNav子菜单
      workName:['"雨润花开"健康雨花立体化支持平台','"雨你同行"社区医疗公益行动','困境妇儿救治金',],
      chooseIndex:0,
      picsTitle:'', //右下角图片标题
      describeList:[
        '为推进“健康雨花”建设，坚持“两个基本点”，打造“一个基地三个平台”，以“雨花区健康教育馆”为基地，以推动健康教育专业发展、提升居民健康素养水平为基本点，建设集培育、培训为一体的健康教育平台；集志愿服务、示范创建为一体的健康教育公益实践平台；集研发、宣传为一体的健康教育倡导平台，形成可复制推广的模式，打造健康教育示范标杆。',
        '“雨”你同行社区医疗公益行动以雨花区洞井街道为服务场域，聚焦社区居民健康议题，通过"硬件”（"雨”你同行·家庭药箱）+"软件”（"雨”你同行·社区义诊和"雨”你同行·妈妈免费体检）双驱动模式,以公益医疗资源为纽带，以社会力量共建为驱动，探索一种新的"政社企”三方共建促进社区居民健康的常态化，可持续服务模式。',
        '长沙市妇幼保健院与长沙群英会针对困境妇儿疾病救助的社会需求，积极探索医疗+志愿服务力量在针对患者的“救急、助贫”工作中发挥专业优势，于2021年9月正式启动困境妇儿救治金项目。通过整合院内公益医疗资源，引进包括政府部门、医疗机构、大病救助NGO组织、各地媒体、爱心企业及社会公众等各类社会资源，以“打通渠道”为根，以“跨界合作”为本，形成高效合作联动模式，搭建公益医疗资源共享平台。为困境儿童、妇女提供就医咨询、疾病救治、就医资金等多方面的专业快捷实效的救助服务，使其不因缺乏治疗费用导致缺医失救。'
      ],
      //地图展示项目数据
      resdata:[
        {
          name: '长沙市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '180',
              serviceCount: '26065',
              volunteerCount: '115',
              schoolCount: '60',
              money: '15',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '24',
              serviceCount: '1519',
              volunteerCount: '46',
              schoolCount: '0',
              money: '5.318',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '85',
              volunteerCount: '0',
              schoolCount: '0',
              money: '21.84',
              attent: '0',
            },
          ]
        },
        {
          name:'株洲市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '17',
              volunteerCount: '0',
              schoolCount: '0',
              money: '2.75',
              attent: '0',
            },
          ]
        },
        {
          name:'湘潭市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '1',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0.3',
              attent: '0',
            },
          ]
        },
        {
          name:'衡阳市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '1',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0.25',
              attent: '0',
            },
          ]
        },
        {
          name:'常德市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '3',
              volunteerCount: '0',
              schoolCount: '0',
              money: '5.5',
              attent: '0',
            },
          ]
        },
        {
          name:'益阳市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '2',
              volunteerCount: '0',
              schoolCount: '0',
              money: '1.7',
              attent: '0',
            },
          ]
        },
        {
          name:'娄底市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '6',
              volunteerCount: '0',
              schoolCount: '0',
              money: '2.36',
              attent: '0',
            },
          ]
        },
        {
          name:'郴州市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'永州市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '14',
              volunteerCount: '0',
              schoolCount: '0',
              money: '1.4',
              attent: '0',
            },
          ]
        },
        {
          name:'怀化市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'湘西土家族苗族自治州',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'张家界市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '1',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0.2',
              attent: '0',
            },
          ]
        },
        {
          name:'岳阳市',
          value: 1,
          choiceIndex: 1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '5',
              volunteerCount: '0',
              schoolCount: '0',
              money: '4.3',
              attent: '0',
            },
          ]
        },
        {
          name:'邵阳市',
          value: 1,
          choiceIndex: 1,
          list:[
            {
              title: '“雨润花开”健康雨花立体化支持平台',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨你同行”社区医疗公益行动',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '困境妇儿救治金',
              soprtcount: '0',
              serviceCount: '1',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0.3',
              attent: '0',
            },
          ]
        },
      ],
      //项目图片数组
      workPicsList:[
        {
          name:'雨润花开项目照片',
          picsList:[
            require('@/assets/medicalHealthy/RainMoistFlower/1.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/2.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/3.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/4.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/5.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/6.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/7.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/8.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/9.png'),
            require('@/assets/medicalHealthy/RainMoistFlower/10.png'),
          ]
        },
        {
          name:'雨你同行项目照片',
          picsList:[
            require('@/assets/medicalHealthy/rainYouTogether/1.png'),
            require('@/assets/medicalHealthy/rainYouTogether/2.png'),
            require('@/assets/medicalHealthy/rainYouTogether/3.png'),
            require('@/assets/medicalHealthy/rainYouTogether/4.png'),
            require('@/assets/medicalHealthy/rainYouTogether/5.png'),
            require('@/assets/medicalHealthy/rainYouTogether/6.png'),
            require('@/assets/medicalHealthy/rainYouTogether/7.png'),
            require('@/assets/medicalHealthy/rainYouTogether/8.png'),
            require('@/assets/medicalHealthy/rainYouTogether/9.png'),
            require('@/assets/medicalHealthy/rainYouTogether/10.png'),
          ]
        },
        {
          name:'困境妇儿救治金',
          picsList:[
            require('@/assets/medicalHealthy/WmChildRescueFund/1.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/2.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/3.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/4.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/5.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/6.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/7.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/8.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/9.jpg'),
            require('@/assets/medicalHealthy/WmChildRescueFund/10.jpg'),
          ]
        },
      ],
      // 各项目累计数据
            totalSport:[],
            totalService:[],
            totalVolunteer:[],
            totalSchool:[],
            totalAttent:[],
            totalMoney:[],
            sumWork:0,
      }
  },
  methods:{
    //  点击子菜单选择
    chooseNav(item,index){
      this.chooseIndex = index;
      this.picsTitle = item;   //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = this.workPicsList[index].picsList;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading=false;
      // 荣誉图片
      // this.$refs.honor.honoraryPicsList = this.workPicsList[index].picsList;
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load=false; //加载中

      //每次点击导航选项将之前数据置空 再进行下面操作
      this.totalSport=[]  //累计活动场次
      this.totalService=[] //累计服务人次
      this.totalAttent=[]  //累计社会组织参与
      this.totalMoney=[]   //累计投入款物
      this.totalVolunteer=[]  //累计志愿者人数
      this.totalSchool=[]  //累计覆盖学校数量

      for (var i=0;i<this.resdata.length;i++){
        this.resdata[i].choiceIndex=index; //选择导航栏 把index对应传给choiceIndex 从而获取不同数据
        this.$refs.taskStatus.sportData[i].value = this.resdata[i].list[index].soprtcount //排行轮播活动场次

        // 累计各项数据 数组新接收
          this.totalSport.push(this.resdata[i].list[index].soprtcount);  //累计活动场次
          this.totalService.push(this.resdata[i].list[index].serviceCount);  //累计服务人次
          this.totalAttent.push(this.resdata[i].list[index].attent);  //累计社会组织参与
          this.totalMoney.push(this.resdata[i].list[index].money);  //累计投入款物
          this.totalVolunteer.push(this.resdata[i].list[index].volunteerCount);  //累计志愿者人数
          this.totalSchool.push(this.resdata[i].list[index].schoolCount);  //累计覆盖学校数量

        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
          let totalValue = Number(this.resdata[i].list[index].attent) + Number(this.resdata[i].list[index].money)
              + Number(this.resdata[i].list[index].schoolCount) + Number(this.resdata[i].list[index].serviceCount)
              + Number(this.resdata[i].list[index].soprtcount) + Number(this.resdata[i].list[index].volunteerCount)
          this.resdata[i].value = totalValue;
      }
      // (父传子）累计各项数据
      this.$refs.taskStatus.sumWork[0].sumWorkList=this.sum(this.totalSport);  //累计活动场次
      this.$refs.taskStatus.sumWork[1].sumWorkList=this.sum(this.totalService);  //累计服务人次
      this.$refs.taskStatus.sumWork[2].sumWorkList=this.sum(this.totalAttent);  //累计社会组织参与
      this.$refs.taskStatus.sumWork[3].sumWorkList=this.sum(this.totalMoney);  //累计投入款物
      this.$refs.taskStatus.sumWork[4].sumWorkList=this.sum(this.totalVolunteer);  //累计志愿者人数
      this.$refs.taskStatus.sumWork[5].sumWorkList=this.sum(this.totalSchool);  //累计覆盖学校数量

      this.$refs.taskStatus.setChart();  //刷新轮播排行活动场次数据
      this.$refs.chinaMap.seriesData = this.resdata
      this.$refs.chinaMap.setMap(); //刷新地图数据
    },
    // 求项目累计数据函数
    sum(arr) {
        var s = 0;
        for (var i = 0;i<arr.length;i++) {
          s += Number(arr[i]);
        }
        return s;
      }
  },
  created() {

  },
  mounted() {
    setTimeout(()=>{
      this.chooseNav(this.workName[0],0);
    },100)
    // this.resizeFn = this.$debounce(()=> {
    //   // 通过捕获系统的onresize事件触发我们需要执行的事件
    //   this.$refs.channelBar1.setChart();
    //   this.$refs.distributionSolider1.setChart();
    //   this.$refs.channelBar2.setChart();
    //   this.$refs.distributionSolider2.setChart();
    //   this.$refs.redPocket.setPocket();
    //   for (let i = 1; i < 9; i++) {
    //     this.$refs['chart' + i].setChart()
    //
    //   }
    //   this.$refs.chinaMap.setMap();
    //
    // }, 500)
    // window.addEventListener('resize', this.resizeFn)
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.resizeFn)
  // }
}
</script>

<style lang="less" scoped>
.page3 {
  height: 99%;
  width: 100%;
  padding: 8px 20px 20px;
  background: #03044A;
  overflow: hidden;
  position: relative;
  .tabNav{
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.3%;
    left:35%;
    .workname{
      color:#fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right:1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
    .on_workname{
      color:#4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }
  .ivu-col {
    height: 100%;
    float: left;
  }

  .right1-1, .center-bottom,.description {
    height: 95%;
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;

    #left_5 {
      height: 100%;
      width: 45%;
      float: left;
    }

    #left_6 {
      height: 100%;
      width: 55%;
      float: left;
    }

    .circular {
      height: 100%;

      .canvas {
        height: 100%;
        width: 30%;
        float: left;

        .subtitle {
          font-size: 12px;
          font-weight: bold;
          color: #fff;
          height: 25px;
          padding: 10px 0 ;
          text-align: center;
        }

        .canvasList {
          height: calc(~'100% - 25px');
          text-align: center
        }
      }
    }

    .left1 {
      border-bottom: 1px solid #0D2451;
      background: #151456;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
      }
    }
  }
  .left{
    height: 76%;
  }
  .description{
    background: none!important;
    height: 20%!important;
    box-sizing: border-box;
    padding-bottom: 2%;
  }
  .describeList{
    text-indent: 3%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 2% auto;
    padding-top: 1%;
    padding-bottom: 1%;
    align-items: center;
    color:rgb(167,198,235);
    line-height: 27px;
    font-size: 15px;
    font-family: "华文细黑", Courier New, Courier, monospace;
    span{
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  .top-list,.center-list,.bottom-left,.bottom-right{
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;
    box-sizing: border-box;

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }
  .bottom-left,.bottom-right{
    width: 50%;
  }
  .top-list{
    height: 18%;
    padding: 0 10px;
    .describe{
      display: inline-block;
      margin-top: 20px;
      //width: 30%;
      height: 98%;
      font-size: 17px;
      color: #3d83e7;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
  }
  .center-list{
    height: 30%;
    margin: 20px 0;
  }
  .bottom-list{
    width: 100%;
    height: 52%;
  }

  .center-bottom {
    height: 40%;

    .bottom-radars {
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
  }

  .right-1 {
    height: 30%;

    .right1-1 {
      height: 92%;
    }
  }

  .right-2 {
    height: 40%;

  }
}
</style>
